// Components
import VuetifyContentLoading from '../VuetifyContentLoading'
import FileUploader from '../FileUploader'
// Mixins
import uiMixin from '@/mixins/uiMixin'
// Services
import { uploadFile, deleteFile, refFromUrl, getDownloadURLFromRef } from '@/services/storage'
// Filters
import { removeExtentionFile } from '@/filters'
// Utils
import { cloneDeep, isNil } from 'lodash'

const uniqid = require('uniqid')

export default {
  name: 'DragDropUploader',
  model: {
    prop: 'value',
    event: 'change'
  },
  components: { VuetifyContentLoading, FileUploader },
  mixins: [uiMixin],
  props: {
    /**
     * Label to upload button
     */
    uploaderLabel: {
      type: String,
      default: 'Selecciona las imágenes a subir'
    },
    /**
     * Maximum size allowed to upload a image
     */
    maxSizeImage: {
      type: Object,
      default() {
        return {
          width: 1280,
          height: 720
        }
      }
    },
    /**
     * Path (firebase storage) where the images will be save
     */
    storagePath: {
      type: String,
      default: 'tests'
    },
    value: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      items: cloneDeep(this.value),
      processingRequest: false
    }
  },
  methods: {
    /**
     * Get the files that the user wants to upload
     *
     * @param {string} error - error to show
     */
    handleError(error) {
      this.modifyAppAlert({
        type: 'error',
        text: error.message,
        visible: true
      })
    },
    /**
     * handle click on "button upload"
     */
    handleAddImage() {
      if (!this.processingRequest) {
        this.$refs.fileUploader.handleClick()
      }
    },
    /**
     * Handle remove image
     *
     * @param {number} index - index
     */
    async handleRemoveImage(index) {
      if (!this.processingRequest) {
        try {
          // Loading
          this.processingRequest = true

          const { url } = this.items[index]

          if (isNil(url)) {
            throw new Error('No existe la imagen que intentó eliminar')
          }
          // Remove image from server
          await this.removeImage(url, index)
        } catch (error) {
          this.handleError(error || 'Hubo un error al intentar eliminar la imagen')
        } finally {
          this.processingRequest = false
        }
      }
    },
    /**
     * Get the files that the user wants to upload
     *
     * @param {array} files - files array to upload
     */
    async handleUploadFiles(files) {
      // upload images to server
      await this.uploadImages(files)
    },
    /**
     * Handle preview image
     *
     * @param {string} url - image url
     * @param {number} index - index inside "items"
     */
    async removeImage(url, index) {
      this.modifyAppAlert({
        actionButtonFn: async () => {
          const ref = await refFromUrl(url)
          // Remove from server
          await deleteFile(ref)
          // Remove from array
          this.items.splice(index, 1)
          // Lanzamos evento de cambio
          this.$emit('change', this.items)
        },
        actionButtonText: 'Eliminar',
        text: '¿Está seguro que desea eliminar la imagen indicada?',
        type: 'warning',
        visible: true
      })
    },
    /**
     * Upload images to Storage
     *
     * @param {array} files - array BLOB
     */
    async uploadImages(files) {
      try {
        // Loading
        this.processingRequest = true
        // Subimos todos los ficheros indicados a Storage
        await Promise.all(
          files.map(async (file) => {
            const fileName = uniqid()
            const path = this.storagePath || uniqid()
            const ref = await uploadFile(file, `${path}/${fileName}`)
            const url = await getDownloadURLFromRef(ref)

            // Add to current available images
            this.items.push({
              name: removeExtentionFile(file.name),
              url
            })
          })
        )

        // Lanzamos evento de cambio
        this.$emit('change', this.items)
      } catch (error) {
        this.handleError(error || 'Hubo un error al intentar subir la imagen')
      } finally {
        this.processingRequest = false
      }
    }
  }
}
