// Components
import CardContainer from '@/components/ui/CardContainer'
import DraggableList from '@/components/ui/DraggableList'
import ImageGalleryForm from '../../components/elements/ImageGalleryForm'
import ImageGalleryUploadForm from '../../components/elements/ImageGalleryUploadForm'
import ImageGalleryListItem from '../../components/elements/ImageGalleryListItem'
import SectionInformationBox from '@/components/ui/SectionInformationBox'
import VuetifyContentLoading from '@/components/ui/VuetifyContentLoading'
import VuetifyToolBar from '@/components/ui/VuetifyToolBar'
// Mixins
import addonsMixin from '@/mixins/addonsMixin'
import uiMixin from '@/mixins/uiMixin'
// Vuex
import { mapGetters, mapState } from 'vuex'
// Services
import { getEveryGalleryImagesByPlaceId, updateGalleryImageById } from '../../services/galleryImage'
// Utils
import { getPathImage } from '@/utils'

export default {
  name: 'ImageGalleryList',
  components: {
    CardContainer,
    DraggableList,
    ImageGalleryListItem,
    SectionInformationBox,
    VuetifyContentLoading,
    VuetifyToolBar
  },
  mixins: [addonsMixin, uiMixin],
  data() {
    return {
      items: [], // Imágenes de la galería
      processingRequest: true
    }
  },
  computed: {
    ...mapState('app', ['extraSmallDevice']),
    ...mapGetters('place', ['placeData']),
    /**
     * Get the options to show the screen when there are not items
     */
    sectionInformationOptions() {
      return {
        media: getPathImage('onboarding/dishes.svg'),
        title: 'Galería de imágenes',
        description: 'Aún no tienes imágenes en tu galería',
        buttonLabel: 'Sube ya la primera'
      }
    },
    /**
     * La "toolBar" es sticky
     *
     * @return {Boolean}
     */
    toolBarIsSticky() {
      return this.extraSmallDevice
    }
  },
  async mounted() {
    // Capturamos evento
    this.$root.$on('reload-list', this.setItems)
    // Obtenemos los datos iniciales
    await this.getEveryNeededData()
  },
  destroyed() {
    // Paramos escucha
    this.$root.$off('reload-list')
  },
  methods: {
    /**
     * Usuario pulsa en botón de añadir imagen
     */
    handleAddImage() {
      this.modifyAppDialog({
        title: 'Subir imágenes',
        contentComponent: ImageGalleryUploadForm,
        hideActionButtons: true,
        visible: true
      })
    },
    /**
     * Usuario pulsa sobre un item
     *
     * @param {Object} item
     */
    handleClickItem(item) {
      this.modifyAppDialog({
        title: 'Editar imagen',
        contentComponent: ImageGalleryForm,
        contentComponentProps: { image: item },
        hideActionButtons: true,
        visible: true
      })
    },
    /**
     * Usuario modifica orden de los items
     *
     * @param {Array} items - items ordered
     */
    async handleChangeOrder(items) {
      if (Array.isArray(items)) {
        items.map(async (item, index) => {
          await updateGalleryImageById({
            id: item.id,
            order: index
          })
        })
      }
    },
    /**
     * Obtenemos todos los datos necesarios para
     * inicializar el listado de categorías
     */
    async getEveryNeededData() {
      // Imágenes de la galería
      await this.setItems()
    },
    /**
     * Obtenemos las imágenes de la galería
     *
     * @return {array}
     */
    async setItems() {
      this.processingRequest = true
      try {
        // Imágenes de la galería
        this.items = await getEveryGalleryImagesByPlaceId(this.placeData.id)
      } catch (error) {
        this.modifyAppAlert({
          text: error.message,
          type: 'error',
          visible: true
        })
      } finally {
        this.processingRequest = false
      }
    }
  }
}
