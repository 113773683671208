// Components
import DragDropUploader from '@/components/ui/DragDropUploader'
import FormButtons from '@/components/ui/FormButtons'
// Mixins
import formMixin from '@/mixins/formMixin'
import uiMixin from '@/mixins/uiMixin'
// Vuelidate plugin
import { validationMixin } from 'vuelidate'
// Vuex
import { mapGetters } from 'vuex'
// Services
import { createGalleryImage } from '../../../services/galleryImage'

export default {
  name: 'ImageGalleryUploadForm',
  components: {
    DragDropUploader,
    FormButtons
  },
  mixins: [formMixin, uiMixin, validationMixin],
  data() {
    return {
      // Form fields
      formFields: {
        images: []
      }
    }
  },
  computed: {
    ...mapGetters('place', ['placeData']),
    /**
     * Path where the image will be saved
     *
     * @return {string}
     */
    storagePath() {
      return `${this.placeData.id}/gallery`
    }
  },
  methods: {
    /**
     * When the user must click on cancel button
     */
    handleCancelButton() {
      this.hideDialog()
    },
    /**
     * Is triggering after the form is correctly
     * validated by "Vuelidate"
     */
    async afterSubmit() {
      // Almacenamos imágenes en BD
      if (this.formFields.images.length) {
        await Promise.all(
          this.formFields.images.map(async (image) => {
            const { name, url } = image
            await createGalleryImage({
              alternative: name,
              title: name,
              url,
              placeId: this.placeData.id
            })
          })
        )

        // show info
        this.modifyAppAlert({
          text: 'Las imágenes fueron subidas correctamente',
          visible: true
        })

        // Evento general para que cualquier lista
        // a la escucha, recargue su listado
        this.$root.$emit('reload-list')
      }

      // Hide dialog
      this.hideDialog()
    },
    /**
     * Hide dialog
     */
    hideDialog() {
      this.modifyAppDialog({
        visible: false
      })
    }
  },
  // Validations with Vuelidate
  validations: {
    formFields: {
      images: {}
    }
  }
}
