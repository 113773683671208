// Utils
import { isNil } from 'lodash'

// Request
import { doRequest } from '@/utils/requestApi'

/**
 * Obtenemos todas las imágenes de un establecimiento
 *
 * @param {string} id - UID place
 */
export async function getEveryGalleryImagesByPlaceId(id) {
  const result = await doRequest({
    method: 'get',
    url: `v1/gallery-image/place/${id}`
  })

  return result.data
}
/**
 * Creamos un nueva imagen en la BD
 *
 * @param {Object} data - galleryImage data
 */
export async function createGalleryImage(data) {
  const { id, ...params } = data
  const result = await doRequest({
    method: 'post',
    url: isNil(id) ? 'v1/gallery-image' : `v1/gallery-image/${id}`,
    data: {
      data: params
    }
  })

  return result.data
}
/**
 * Update featuredItem in database
 *
 * @param {Object} data - galleryImage data
 */
export async function updateGalleryImageById(data) {
  const { id, ...params } = data
  const result = await doRequest({
    method: 'patch',
    url: `v1/gallery-image/${id}`,
    data: {
      data: params
    }
  })

  return result.data
}
/**
 * Obtenemos los datos de una imagen de la galería
 *
 * @param {string} id - UID galleryImage in database
 */
export async function getGalleryImageById(id) {
  const result = await doRequest({
    method: 'get',
    url: `v1/gallery-image/${id}`
  })

  return result.data
}
/**
 * Eliminamos una imagen de la galería
 *
 * @param {string} id - UID galleryImage in database
 */
export async function deleteGalleryImageById(id) {
  const result = await doRequest({
    method: 'delete',
    url: `v1/gallery-image/${id}`
  })

  return result.data
}
