export default {
  name: 'ImageGalleryListItem',
  props: {
    item: {
      required: true,
      type: Object,
      default() {
        return {}
      }
    },
    height: {
      type: Number,
      default: 125
    },
    width: {
      type: Number,
      default: 125
    }
  },
  methods: {
    /**
     * Handle click on item
     *
     * @param {string} item
     */
    handleClickItem(item) {
      this.$emit('onClickItem', item)
    }
  }
}
