// Components
import DragDropUploader from '@/components/ui/DragDropUploader'
import FormButtons from '@/components/ui/FormButtons'
// Mixins
import formMixin from '@/mixins/formMixin'
import uiMixin from '@/mixins/uiMixin'
// Vuelidate plugin
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
// Services
import { updateGalleryImageById, deleteGalleryImageById } from '../../../services/galleryImage'
// Utils
import { isNil } from 'lodash'

export default {
  name: 'ImageGalleryForm',
  components: {
    DragDropUploader,
    FormButtons
  },
  mixins: [formMixin, uiMixin, validationMixin],
  props: {
    image: {
      required: true,
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      // Form fields
      formFields: {
        title: null,
        alternative: null
      },
      formFieldsValidations: {
        title: {
          required: 'Campo obligatorio'
        },
        alternative: {
          required: 'Campo obligatorio'
        }
      }
    }
  },
  mounted() {
    this.getEveryNeededData()
  },
  methods: {
    /**
     * When the user must click on delete button
     */
    async handleDeleteButton() {
      this.modifyAppAlert({
        actionButtonFn: async () => {
          // Remove image
          const { ok } = await deleteGalleryImageById(this.image.id)

          if (!ok) {
            throw new Error('Hubo un error al intentar eliminar la imagen.')
          }
          // Evento general para que cualquier lista
          // a la escucha, recargue su listado
          this.$root.$emit('reload-list')

          // Hide dialog
          this.hideDialog()
        },
        actionButtonText: 'Borrar',
        text: '¿Desea borrar la imagen?',
        type: 'warning',
        visible: true
      })
    },
    /**
     * When the user must click on cancel button
     */
    handleCancelButton() {
      this.hideDialog()
    },
    /**
     * Obtenemos todo lo necesario para inicializar el componente
     */
    getEveryNeededData() {
      if (isNil(this.image)) {
        this.hideDialog()
      } else {
        this.setFormFieldsValues()
      }
    },
    /**
     * Establecemos todos los valores de los campos del formulario
     */
    setFormFieldsValues() {
      this.formFields.title = this.image.title
      this.formFields.alternative = this.image.alternative
    },
    /**
     * Is triggering after the form is correctly
     * validated by "Vuelidate"
     */
    async afterSubmit() {
      // Salvamos modificaciones
      await updateGalleryImageById({
        ...this.formFields,
        id: this.image.id
      })

      // show info
      this.modifyAppAlert({
        text: 'Los datos fueron modificados correctamente',
        visible: true
      })

      // Evento general para que cualquier lista
      // a la escucha, recargue su listado
      this.$root.$emit('reload-list')

      // Hide dialog
      this.hideDialog()
    },
    /**
     * Hide dialog
     */
    hideDialog() {
      this.modifyAppDialog({
        visible: false
      })
    }
  },
  // Validations with Vuelidate
  validations: {
    formFields: {
      title: { required },
      alternative: { required }
    }
  }
}
